<template>
  <div
    class="share-asset-named-item"
    :class="[
      { 'share-asset-named-item--navigable': canNavigate },
      $attrs.class
    ]"
  >
    <div class="share-asset-named-item__avatar" @click.prevent="selected">
      <AvatarPill :info="item.author" />
    </div>
    <div class="share-asset-named-item__main" @click.prevent="selected">
      <div class="share-asset-named-item__fullname">
        {{ item.firstName }} {{ item.lastName }}
      </div>
      <div v-if="item.email" class="share-asset-named-item__email">
        {{ item.email }}
      </div>
      <div v-if="item.phoneNumber" class="share-asset-named-item__phone-number">
        {{ item.phoneNumber }}
      </div>
      <div v-if="displayAsset" class="share-asset-named-item__main--asset">
        <ItemIcon
          :type="asset.type"
          :item="asset"
          .withLink="false"
          smallIcon
        />
      </div>
    </div>

    <div class="share-asset-named-item__comment">
      <CommentIndicator
        :item="item"
        :type="shareType"
        :raiseEventOnClick="true"
        @click.prevent="displayComments"
      />
    </div>

    <div class="share-asset-named-item__open-count">
      {{ item.openCount }}
    </div>
    <div
      class="share-asset-named-item__action"
      @click="toggleDialogue('options')"
    >
      <Action size="small" />
    </div>
    <div
      v-if="canNavigate"
      class="share-asset-named-item__next"
      @click.prevent="selected"
    >
      <Chevron size="xsmall" direction="right" />
    </div>

    <!-- Options dialogue -->
    <ShareAssetOptionsDialogue
      v-if="activeDialogue === 'options'"
      :item="item"
      @cancel="toggleDialogue()"
      @customizeShare="emitCustomizeShare"
    />
  </div>
</template>

<script>
import Chevron from '@/components/icons/Chevron';
import Action from '@/components/icons/Action';
import ShareAssetOptionsDialogue from '@/components/shareAsset/ShareAssetOptionsDialogue';
import ItemIcon from '@/components/layout/ItemIcon';
import AvatarPill from '../AvatarPill';
import CommentIndicator from '@/components/comment/CommentIndicator';
import { useRouter } from 'vue-router';
import { useToggleState } from '@/composables/toggle-state';
import { computed } from 'vue';
import { CARD_TYPE, SHARE_ASSET_TYPE } from '@/utils/types';

export default {
  inheritAttrs: false,
  name: 'ShareAssetNamedItem',
  components: {
    Chevron,
    Action,
    ShareAssetOptionsDialogue,
    ItemIcon,
    AvatarPill,
    CommentIndicator
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    displayAsset: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { activeDialogue, toggleDialogue } = useToggleState('dialogue');

    const canNavigate = computed(() => {
      return props.item?.assetType === CARD_TYPE;
    });

    const shareType = computed(() => {
      return SHARE_ASSET_TYPE;
    });

    const navigateTo = (displayComments) => {
      router.push({
        name: SHARE_ASSET_TYPE,
        params: { id: props.item.id },
        query: displayComments ? { comments: true } : {}
      });

      emit('selected');
    };

    return {
      canNavigate,
      shareType,

      asset: computed(() => {
        return {
          ...props.item.asset,
          type: props.item.assetType,
          id: props.item.assetId
        };
      }),

      activeDialogue,
      toggleDialogue,

      selected: () => {
        if (!canNavigate.value) return;
        navigateTo();
      },
      displayComments: () => {
        if (!canNavigate.value) return;
        navigateTo(document.body.clientWidth >= 768);
      },
      emitCustomizeShare: () => {
        emit('customizeShare');
      }
    };
  }
};
</script>

<style lang="scss">
.share-asset-named-item {
  @include grid-item(item);
  @include grid(
    $gap: 0,
    $cols: auto 1fr auto auto auto auto,
    $areas: 'avatar main comment open-count action next'
  );

  min-height: $height-item;
  color: $font-dark;
  background-color: #ffffff;

  &--navigable {
    cursor: pointer;
  }

  &__avatar {
    @include grid-item(avatar);
    @include flexy($just: center, $align: center);
    margin: 0 $spacing-half;
    margin-right: 0;
    height: 100%;
  }

  &__main {
    @include grid-item(main);
    @include flexy($dir: column, $just: center, $align: left);
    padding: $spacing-half;

    &--asset {
      font-style: italic;
      font-size: $font-size-small;
    }
  }

  &__comment {
    @include grid-item(comment);
    @include flexy($align: center);
    padding: $spacing-quarter;
  }

  &__open-count {
    @include grid-item(open-count);
    @include flexy($align: center);
    padding: $spacing-quarter;
  }

  &__action {
    @include grid-item(action);
    @include flexy($align: center);
    padding: $spacing-quarter;
    cursor: pointer;
  }

  &__next {
    @include grid-item(next);
    @include flexy($align: center);
    padding: $spacing-quarter;
    padding-right: $spacing-half;
  }
}
</style>
