<template>
  <div class="share-asset-filter-menu" :class="$attrs.class">
    <SliderContent
      v-if="!currentAsset"
      ref="byTypes"
      class="share-asset-filter-menu__slider-content"
      :label="$t('shareAsset.filterMenu.byTypes')"
      :displayIndicator="false"
      @update:modelValue="expand('byTypes', $event)"
    >
      <div
        class="share-asset-filter-menu__item"
        v-for="type in assetTypes.filter((x) => x !== MEDIA_TYPE)"
        :key="type"
      >
        <BaseCheckbox
          class="share-asset-filter-menu__item--check"
          :label="$t(`types.${type}`)"
          v-model="form.types[type]"
        />
      </div>
    </SliderContent>

    <SliderContent
      v-if="!currentAsset"
      ref="byDates"
      class="share-asset-filter-menu__slider-content"
      :label="$t('shareAsset.filterMenu.byDates')"
      @update:modelValue="expand('byDates', $event)"
    >
      <div class="share-asset-filter-menu__item">
        <BaseRadio
          class="share-asset-filter-menu__item--check"
          :label="$t('shareAsset.filterMenu.lastWeek')"
          name="dateOptions"
          value="lastWeek"
          v-model="form.dates.option"
          canUnSelect
          @update:modelValue="optionDateChanged"
        />
      </div>
      <div class="share-asset-filter-menu__item">
        <BaseRadio
          class="share-asset-filter-menu__item--check"
          :label="$t('shareAsset.filterMenu.lastMonth')"
          name="dateOptions"
          value="lastMonth"
          v-model="form.dates.option"
          canUnSelect
          @update:modelValue="optionDateChanged"
        />
      </div>
      <div class="share-asset-filter-menu__item">
        <BaseRadio
          class="share-asset-filter-menu__item--check"
          :label="$t('shareAsset.filterMenu.last3Months')"
          name="dateOptions"
          value="last3Months"
          v-model="form.dates.option"
          canUnSelect
          @update:modelValue="optionDateChanged"
        />
      </div>

      <div class="share-asset-filter-menu__item double">
        <div class="share-asset-filter-menu__item__date">
          <div class="share-asset-filter-menu__item__date--from-label">
            {{ $t('shareAsset.filterMenu.from') }}
          </div>
          <BaseDatetimeInput
            class="share-asset-filter-menu__item__date--from-field"
            name="fromDate"
            :enableTimePicker="false"
            v-model="form.dates.from"
            autoApply
            @update:modelValue="dateSelectionChanged"
          />

          <div class="share-asset-filter-menu__item__date--to-label">
            {{ $t('shareAsset.filterMenu.to') }}
          </div>
          <BaseDatetimeInput
            class="share-asset-filter-menu__item__date--to-field"
            name="toDate"
            :enableTimePicker="false"
            autoApply
            v-model="form.dates.to"
            @update:modelValue="dateSelectionChanged"
          />
        </div>
      </div>
    </SliderContent>

    <div class="share-asset-filter-menu__check">
      <BaseCheckbox
        class="share-asset-filter-menu__check--input"
        :label="$t('shareAsset.filterMenu.allShares')"
        v-model="form.allShares"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useShareAssetSearchStore } from '@/stores/share-asset-search';
import authMixins from '../mixins/auth';
import SliderContent from '../forms/SliderContent';
import { MEDIA_TYPE, ASSET_TYPES, BOARD_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'ShareAssetFilterMenu',
  mixins: [authMixins],
  components: {
    SliderContent
  },
  data() {
    return {
      form: {},
      innerHasSelectedMediaTypes: false
    };
  },
  computed: {
    ...mapState(useShareAssetSearchStore, ['currentAsset', 'filter']),
    MEDIA_TYPE() {
      return MEDIA_TYPE;
    },
    assetTypes() {
      return ASSET_TYPES.filter(
        (x) => x !== BOARD_TYPE || (x === BOARD_TYPE && this.isAdministrator)
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useShareAssetSearchStore, ['setFilter']),
    init() {
      if (this.currentAsset) {
        this.form = {
          allShares: !!this.filter?.allShares
        };
      } else {
        const filter = {
          ...(this.filter || {
            types: {}
          })
        };

        const form = {
          types: {},
          dates: { ...filter.dates } || {
            option: null,
            from: null,
            to: null
          },
          allShares: !!filter.allShares
        };

        this.assetTypes.forEach((x) => {
          form.types[x] = !!filter.types[x];
        });

        form.dates.option = form.dates.option || null;
        form.dates.from = form.dates.from || null;
        form.dates.to = form.dates.to || null;

        this.form = form;
      }
    },
    optionDateChanged() {
      const { dates } = this.form;

      if (dates.option) {
        dates.from = null;
        dates.to = null;
        // this.$forceUpdate();
      }
    },
    dateSelectionChanged() {
      const { dates } = this.form;

      if (dates.from || dates.to) {
        dates.option = null;
        // this.$forceUpdate();
      }
    },
    expand(ref, expanded) {
      if (!expanded) return;

      if (ref === 'byTypes') {
        this.$refs.byDates.close();
      } else if (ref === 'byDates') {
        this.$refs.byTypes.close();
      }
    },
    apply() {
      const setFilter = (filter) => {
        this.setFilter(filter ? { ...filter } : null);
        this.$emit('applied');
      };

      if (this.currentAsset) {
        if (this.form.allShares) {
          setFilter(this.form);
          return;
        }
      } else {
        for (const p in this.form.types) {
          if (this.form.types[p]) {
            setFilter(this.form);
            return;
          }
        }

        for (const p in this.form.dates) {
          if (this.form.dates[p]) {
            setFilter(this.form);
            return;
          }
        }

        if (this.form.allShares) {
          setFilter(this.form);
          return;
        }
      }

      setFilter(null);
    }
  },
  beforeMount() {
    this.init();
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';

.share-asset-filter-menu__slider-content > .slider-content__fieldset {
  background-color: $background-dark-3;
  color: $font-dark-2;
  margin-bottom: 1px;
}
</style>

<style lang="scss" scoped>
.share-asset-filter-menu {
  background-color: $background-light;

  &__check {
    @include flexy($align: center);
    height: $vertical-rhythm;
    background-color: $background-dark-3;
    color: $font-dark-2;
    margin-bottom: 1px;
    padding: 0 $spacing-base;
  }
}

.share-asset-filter-menu__item {
  @include flexy($align: center);
  height: $vertical-rhythm;
  width: 100%;
  background-color: $background-light;
  padding: 0 $spacing-base;

  &--media {
    padding: 0 $spacing-half;
  }

  &--media-type {
    padding: 0 ($spacing-base * 2);
  }

  &__date {
    @include grid(
      $gap: $spacing-half,
      $cols: auto auto,
      $rows: auto auto,
      $areas: 'label1 field1' 'label2 field2'
    );

    &--from-label {
      @include grid-item($area: label1, $align: center);
    }

    &--from-field {
      @include grid-item($area: field1, $align: center);
    }

    &--to-label {
      @include grid-item($area: label2, $align: center);
    }

    &--to-field {
      @include grid-item($area: field2, $align: center);
    }
  }
}

.share-asset-filter-menu__item.double {
  height: $vertical-rhythm * 2;
}

.share-asset-filter-menu__item .form-field {
  margin: 0;
}
</style>
